import React from 'react';

const LandingPage = () => {
    return (
        <div
            style={{
            width: '100%',
            height: '100vh',
            overflow: 'hidden', // Hide any scrollbars from the parent container
            margin: 0,
            padding: 0
            }}
        >
        <iframe
            src="https://transparentpropertymanagement.durablesites.com/?pt=NjZhMGQ0YWQ3NzRhMWQ0ZmZkM2M2ZDJiOjE3MjM2MTM1NDYuOTk2OnByZXZpZXc="
            style={{ 
                width: '100%', 
                height: '100vh', 
                overflow: 'hidden', // Hide any scrollbars from the parent container
                margin: 0,
                padding: 0 }}
            title="Landing Page"
        />
        </div>
    );
};

export default LandingPage;
